<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/documents">Dokumente</router-link></li>
              <li>Rechnung #{{invoice.id }}</li>
            </ul>
            <div class="header-actions">

            </div>
          </header>

          <div class="admin-body">

            <form @submit="update_invoice" class="form" method="post">

              <h3>Provisionszeitraum anpassen</h3>
              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap select-wrap">
                    <label for="pickup_time">Monat</label>
                    <select v-model="invoice.commission_month" id="pickup_time">
                      <option value="jan">Januar</option>
                      <option value="feb">Februar</option>
                      <option value="mar">März</option>
                      <option value="apr">April</option>
                      <option value="mai">Mai</option>
                      <option value="jun">Juni</option>
                      <option value="jul">Juli</option>
                      <option value="aug">August</option>
                      <option value="sep">September</option>
                      <option value="okt">Oktober</option>
                      <option value="nov">November</option>
                      <option value="dez">Dezember</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap select-wrap">
                    <label for="pickup_time">Jahr</label>
                    <select v-model="invoice.commission_year" id="pickup_time">
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                    </select>
                  </div>
                </div>
              </div>

              <p style="margin-top:0;margin-bottom:0">
                <input type="submit" value="Änderungen speichern ›" class="button button-orange button-small">
              </p>

            </form>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_documents',
  components: {
    AdminNav
  },
  data () {
    return {
      loading: true,
      invoice: {}
    }
  },
  methods: {
    get_data() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/invoices/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.invoice = response.data.invoice;
        this.loading = false;
      })
    },
    update_invoice(e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/invoices/'+this.$route.params.id, {
        commission_month: this.invoice.commission_month,
        commission_year: this.invoice.commission_year
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(() => {
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.get_data();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Fehler',
            text: val
          });
        })
      })
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
